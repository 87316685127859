import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Audit, CardSet, FileGroup, Section, Submission, SubmissionReduced } from '../../_models';
import { ActionItem } from '../../_models/action-item';
import { ActionItemComment } from '../../_models/action-item-comments';
import { Region } from '../../_models/region';
import { RestProvider } from '../rest/rest';
import { forEach } from '@angular/router/src/utils/collection';


@Injectable()
export class Data {

  public regionList: Region[] = [];
  public currentRegionID = -1;
  public currentSelectedYear = 0;
  public currentYear = new Date().getFullYear();
  ; // todo maybe not hard code the year... - sean
  public auditList: Audit[] = [];
  
  public currentAudit: any;
  public currentAuditID = -1;
  public currentSubmission: any;
  public currentSubmissionID = -1;
  public currentSection: any;
  public currentSectionID = -1;
  public currentQuestion: CardSet; // card_set
  public currentQuestionID = -1;
  public cardActionItems: { [key: number]: { [k: number]: ActionItem } } = {};
  public auditSubmissions = [
    'submission_jan', 'submission_feb', 'submission_mar', 'submission_q1', 'submission_apr', 'submission_may',
    'submission_jun', 'submission_q2', 'submission_b1', 'submission_jul', 'submission_aug', 'submission_sep', 'submission_q3',
    'submission_oct', 'submission_nov', 'submission_dec', 'submission_q4', 'submission_b2', 'submission_annual'
  ];
  public loadingAuditList = new BehaviorSubject<boolean>(false);
  public loadingAuditList$ = this.loadingAuditList.asObservable();
  private loadingSubmissionList = new BehaviorSubject<boolean>(false);
  public loadingSubmissionList$ = this.loadingSubmissionList.asObservable();
  private loadingSectionList = new BehaviorSubject<boolean>(false);
  public loadingSectionList$ = this.loadingSectionList.asObservable();
  private loadingQuestionList = new BehaviorSubject<boolean>(false);
  public loadingQuestionList$ = this.loadingQuestionList.asObservable();
  private loadingCardAttachments = new BehaviorSubject<boolean>(false);
  public loadingCardAttachments$ = this.loadingCardAttachments.asObservable();
  private loadBreadcrumb = new BehaviorSubject<boolean>(false);
  public loadBreadcrumb$ = this.loadBreadcrumb.asObservable();
  public loadingRegionList = new BehaviorSubject<boolean>(false);
  public loadingRegionList$ = this.loadingRegionList.asObservable();
  public totalCountOfResponse = 0;
  public auditTableDisplayColumns: string[] = ['name'];
  tableRes: any = [];
  action_list: { text: string, style: string }[] = [];

  constructor(private restProvider: RestProvider, private _datePipe: DatePipe) { }

  static updatePercentMetaData(
    questionsAnswered: number,
    questionsTotal: number,
    pointsAllocated: number,
    pointsAwarded: number,
    pointsAvailable: number,
    lciCount: number
  ) {

    const section = {
      questions_answered: questionsAnswered,
      questions_total: questionsTotal,
      points_allocated: pointsAllocated,
      points_awarded: pointsAwarded,
      points_available: pointsAvailable,
      percent: Math.round((pointsAwarded / pointsAllocated) * 100) - lciCount,
      lci_count: lciCount,
      percentColour: '',
    };

    if (pointsAllocated === 0) { section.percent = 100; }

    if (section.percent < 90) { section.percentColour = '#FF2600'; }
    else if (section.percent < 95) { section.percentColour = '#FF9300'; }
    else { section.percentColour = '#017100'; }

    return section;
  }


  static initialiseSubmissionMetaData(submission: any) {
    if (submission.dash_points_allocated === 0) { // submission not initialised otherwise I'll have 0/0
      submission.dash_points_allocated = submission.points_allocated;
    }

    // this way round to make sure percentage is on the submission
    submission = Object.assign(submission, this.updatePercentMetaData(
      submission.questions_answered,
      submission.questions_total,
      submission.points_allocated,
      submission.points_awarded,
      submission.points_available,
      submission.lci_count
    ));

    if (submission.status > 1) {
      this.submitSubmission(submission);
    }

  }

  static submitSubmission(submission: any) {
    submission.dash_points_awarded = submission.points_awarded;
    submission.dash_percent = submission.percent;
    submission.dash_questions_answered = submission.questions_answered;
    submission.dash_points_allocated = submission.points_allocated;
    submission.dash_lci_count = submission.lci_count;
  }

  generateActionList(submission: any) {
    // let actionList: any = [];
    let actionList = '';
    for (let i = 0; i < submission.sections.length; i++) {
      for (let j = 0; j < submission.sections[i].card_set.length; j++) {
        if (submission.sections[i].card_set[j].card_type === 'QUESTION' || submission.sections[i].card_set[j].card_type === 'COMPLIANCE_RATIO') {
          // +------------+----+------+------+-------+
          // |            | OK | FAIL |  N/A |  NONE |
          // +------------+----+------+------+-------+
          // | comment    | T  | T    | T    | F     |
          // | no comment | F  | T    | F    | F     |
          // +------------+----+------+------+-------+
          // if (submission.sections[i].card_set[j].answer !== 'NONE') {
          //   if (submission.sections[i].card_set[j].answer === 'FAIL' || (submission.sections[i].card_set[j].comments) && (submission.sections[i].card_set[j].comments.length != 0)) {
          if (submission.sections[i].card_set[j].answer === 'FAIL' || (submission.sections[i].card_set[j].comments || []).length > 0) {
            // actionList.push({'text': submission.sections[i].card_set[j].text, ''       });
            // actionList.push(submission.sections[i].card_set[j]);
            actionList += '* ';
            actionList += submission.sections[i].card_set[j].text;
            if ((submission.sections[i].card_set[j].comments) && (submission.sections[i].card_set[j].comments.length != 0)) {
              actionList += '\r\n\t- ';
              actionList += submission.sections[i].card_set[j].comments;
            }
            actionList += '\r\n';
            actionList += '\r\n';
          }
          // }
        }
      }
    }
    return actionList;
  }

  updateSubmissionDashboardPercentage(submission: any, percentage: number) {
    const calcPointsAwarded = Math.trunc(percentage * (submission.points_available / 100));
    if (calcPointsAwarded !== submission.dash_points_awarded) { // used to reduce unessaery calculations and calls to the backend
      submission.dash_points_awarded = calcPointsAwarded;
      submission.dash_points_allocated = submission.points_available;
      submission.dash_percent = percentage; // TODO does ths variable ever get used really
      this.restProvider.updateSubmissionByIDForDashBoard(submission);
    }
  }

  getRegionList(enabledRegions: number[]) {
    this.loadingRegionList.next(true);
    return this.restProvider.getRegionList()
      .then((results) => {

        // for (let ind in results) { results[ind]['canEdit'] = enabledRegions.includes(results[ind].id); }
        const regions = results.map(x => { x.canEdit = enabledRegions.includes(x.id); return x; });

        this.regionList = regions;

        this.loadingRegionList.next(false);
        if (regions.length > 0) { this.loadingAuditList.next(true); }
        return regions;
      })
      .catch((err) => {
        console.log(err);
        this.loadingRegionList.next(false);
        return [];
      });
  }

  getAuditList(regionID: number, year: number) {
    this.currentRegionID = regionID;
    this.currentSelectedYear = year;
    this.loadingAuditList.next(true);
    this.restProvider.getAuditList(regionID, year).then((results: Audit[]) => {

      const keys = ['annual', 'apr', 'aug', 'b1', 'b2', 'dec', 'feb', 'jan', 'jul', 'jun', 'mar', 'may', 'nov', 'oct', 'q1', 'q2', 'q3', 'q4', 'sep'];
      let totalCountOfResponse = 0;
      if (results && results.length) {
        const submissions = results[0].submissions;
        
       
          for (let result of results) {

            // result['isR'] = keys.some(k => result['submission_' + k].action_items_needing_review !== null);
            
            // result['isR'] = result.submissions.some(s => s.action_items_needing_review !== null);
            result['isR'] = false;
            this.auditTableDisplayColumns = ['name'];
            result.submissions.forEach(submission => {
              if (submission.action_items_needing_review !== null) {
                totalCountOfResponse += submission.action_items_needing_review; // Set a the total Actions Items needing review
                result['isR'] = true; // Set a property to allow the UI to know there are Action Items needing review on audit
              }
              Data.initialiseSubmissionMetaData(submission);
              // Generate the Mat Table display headers
              this.auditTableDisplayColumns.push(submission.name);
              
            });
          }

        // }
        
  
        // let totalCountOfResponse = 0;
  
        // for (let result of results) {
        //   for (let key of keys) {
        //     if (result['submission_' + key].action_items_needing_review !== null) {
        //       totalCountOfResponse += result['submission_' + key].action_items_needing_review;
        //     }
        //   }

        // }
  
        this.auditList = results;
        this.totalCountOfResponse = totalCountOfResponse;
  
  
        // // this.currentAudit = results;
        // // this.currentSubmission = this.currentAudit.submission_q1;
        // for (let i = 0; i < this.auditList.length; i++) {
        //   if ('submission_jan' in this.auditList[i]) {
        //     for (let j = 0; j < this.auditSubmissions.length; j++) {
        //       Data.initialiseSubmissionMetaData(this.auditList[i][this.auditSubmissions[j]]);
        //     }
        //   }
        // }
        
      }
      

      this.loadingAuditList.next(false);
      this.loadBreadcrumb.next(true);

    }, (err) => {
      console.log(err);
    });
  }

  switchAudit(auditID: number) {
    this.loadingSubmissionList.next(true);
    this.currentAuditID = auditID; // TODO ponder about this decision

    if (this.currentAudit) {

    }
    for (let i = 0; i < this.auditList.length; i++) {
      if (this.auditList[i].id === auditID) {
        const audit = this.auditList[i];
        // Here because I load the name for the breadcrumb trail off the currentSubmission.name. The submission list should attempt to load
        // submissions until loadingSubmissionList=false
        this.currentAudit = audit;

        // TODO this only checks if one submission is in the list maybe do a check on all the submissions
        // if ('submission_jan' in this.auditList[i]) {

        if (audit.submissions && audit.submissions.length > 0) {
          // no need to add the additional percentage and colour fiels as it was added on api load
  
          this.loadingSubmissionList.next(false);
        } else {
          this.restProvider.getAuditById(auditID)
            .then((result) => {

              result.submissions.forEach(submission => {
                submission = Object.assign(submission, Data.updatePercentMetaData(
                  submission.questions_answered,
                  submission.questions_total,
                  submission.points_allocated,
                  submission.points_awarded,
                  submission.points_available,
                  submission.lci_count
                ));
                  
              });
              // for (let j = 0; j < this.auditSubmissions.length; j++) {

              
              
              //   result[this.auditSubmissions[j]] = Object.assign(result[this.auditSubmissions[j]], Data.updatePercentMetaData(
              //     result[this.auditSubmissions[j]].questions_answered,
              //     result[this.auditSubmissions[j]].questions_total,
              //     result[this.auditSubmissions[j]].points_allocated,
              //     result[this.auditSubmissions[j]].points_awarded,
              //     result[this.auditSubmissions[j]].points_available,
              //     result[this.auditSubmissions[j]].lci_count
              //   ));

              // }

              this.auditList[i] = result; // done instead of current audit incase user selects another audit in while waiting

              if (this.currentAuditID === auditID) {
                this.currentAudit = this.auditList[i]; // this would shift views if it was the wrong id
                this.loadingSubmissionList.next(false);
              }

            }, (err) => {
              console.log(err);
            });
        }

        break;
      }
    }
    this.loadBreadcrumb.next(true);
  }

  toggleLoading(value){
    this.loadingSectionList.next(value);
  }

  switchSubmission(submission: Submission, role: number) {
    this.loadingSectionList.next(true);
    if (this.currentSubmission) { }
    // for (let i = 0; i < this.auditSubmissions.length; i++) {
      // This loop is simply to get the position of the submission in the audit list. This allows us to save the data back easily even if
      // the user tys clicking and opening another submission
      // if (this.currentAudit[this.auditSubmissions[i]].id === submissionID) {
        // Here because I load the name for the breadcrumb trail off the currentSubmission.name. The submission list should attempt to load
        // submissions until loadingSubmissionList=false
        this.currentSubmission = submission;
        this.currentSubmissionID = submission.id;

        if ('sections' in this.currentSubmission) {
          // no need to add the additional percentage and colour fiels as it was added on api load

          this.loadingSectionList.next(false);
        } else {
          const stashedAuditID = this.currentAuditID;
          this.restProvider.getSubmissionById(this.currentSubmissionID)
            .then(async (submission: any) => {
              const actionItems = await this.restProvider.getAllActionItems({ 'submission': this.currentSubmissionID });
              return { submission, actionItems };
            })
            .then(({ submission, actionItems }) => {
              if (stashedAuditID === this.currentAuditID) {
                // // this audit will overide the current one so we need to add back into it the percentage and the colour fields
                // Data.updatePercentMetaData(result, result.questions_answered, result.questions_total, result.points_allocated,
                //   result.points_awarded, result.points_available, result.lci_count);
                //
                const returnSubmission = this.moveSubmisionDataAddSectionMetaData(this.currentSubmission, submission);

                // const actionItemsInCard: { [k: number]: any } = {};

                actionItems['results'].forEach((actionItem) => {
                  // this.cardActionItems[actionItem.card]
                  if (!this.cardActionItems[actionItem.card]) {
                    this.cardActionItems[actionItem.card] = {};
                  }
                  this.cardActionItems[actionItem.card][actionItem.id] = actionItem;

                  // if (!actionItemsInCard[actionItem.card]) {
                  //   actionItemsInCard[actionItem.card] = {
                  //     id: actionItem.card,
                  //     actionItemIds: [],
                  //     status_1: 0,
                  //     status_2: 0,
                  //   };
                  // }
                  // actionItemsInCard[actionItem.card].actionItemIds.push(actionItem.id);
                  // if (actionItem.status === 1) { actionItemsInCard[actionItem.card].status_1 += 1; }
                  // if (actionItem.status === 2) { actionItemsInCard[actionItem.card].status_2 += 1; }
                  // actionItemsInCard[actionItem.card]. += 1;
                })

                // returnSubmission.sections.forEach((section, i) => {
                //   section.action_items_needing_review = {
                //     status_1: 0,
                //     status_2: 0,
                //   }
                //   section.card_set.forEach((card, j) => {
                //     const AIC = actionItemsInCard[card.id];
                //     if (AIC) {
                //       section.action_items_needing_review.status_1 += AIC.status_1;
                //       section.action_items_needing_review.status_2 += AIC.status_2;
                //     }
                //   });
                // });

                const { action_list, action_list_text } = this.createActionList(returnSubmission, this.cardActionItems, role);
                returnSubmission.action_list_text = action_list_text;
                returnSubmission.action_list = action_list;

                if (this.currentSubmissionID === returnSubmission.id) {
                  this.currentSubmission = returnSubmission; // TODO on second view idk if need this here but
                  // need to check
                  this.loadingSectionList.next(false);
                }
              } else { // Todo a possible optimisation of saving the received submission even though the user has left that audit entirely
                // would require another loop through the audits to find the correct audit id and then save the submission
              }

            }, (err) => {
              console.log(err);
            });
        }
        // break;
      // }

    this.loadBreadcrumb.next(true);
  }

  updateActionItems({ type, card, id, data, role }: {
    type: 'card_update' | 'action_delete' | 'action_create' | 'action_update' | 'comment_create',
    card: number,
    id: number,
    role: number,
    data?: ActionItem | CardSet | ActionItemComment,
  }) {

    // console.log("data >>>>>>>>>>>>>", data);
    console.log("type >>>>>>>>>>>>>", type);

    if (this.currentSubmission) {
      if (type === 'card_update' && data) {

        // const sectionIndex = (this.currentSubmission as Submission).sections
        //   .findIndex(sec => sec.id === _data.section);

        // const section = (this.currentSubmission as Submission).sections[sectionIndex];

        // const cardIndex = section && section.card_set
        //   .findIndex(_card => _card.id === _data.id);

        // (this.currentSubmission as Submission).sections[sectionIndex].card_set[cardIndex] = _data;

      } else if (type === 'action_delete') {
        delete this.cardActionItems[card][id];
      } else if (type === 'action_create' && data) {
        if (!this.cardActionItems[card]) { this.cardActionItems[card] = {}; }
        this.cardActionItems[card][id] = data as ActionItem;
      } else if (type === 'action_update' && data) {
        this.cardActionItems[card][id] = data as ActionItem;
      } else if (type === 'comment_create' && data) {
        const comments: ActionItemComment[] = this.cardActionItems[card][id].comments || [];
        comments.push(data as ActionItemComment);
        this.cardActionItems[card][id].comments = comments;
      }

      this.createActionList(this.currentSubmission, this.cardActionItems, role);
    }
  }

  createActionList(
    submission: Submission,
    _cardActionItems: { [key: number]: { [k: number]: ActionItem, }, },
    role: number
  ) {
    const submission_status:
      { status: number, section: { [k: number]: { status: number } } } =
      { status: 0, section: {} };

    let action_list: { text: string, style: string }[] = [];
    let action_list_text = '';

    action_list.push({ text: this.currentAudit.name, style: 'title' });
    action_list_text += this.currentAudit.name;

    console.log('_cardActionItems: ', _cardActionItems);

    submission.sections.forEach((section, i) => {

      if (!submission_status.section[section.id]) {
        submission_status.section[section.id] = { status: 0 };
      }

      const section_action_list: { text: string, style: string }[] = [];
      let section_action_list_text = '';

      section.card_set.forEach((card, j) => {
        const shouldUpdateActionList = card.answer === 'FAIL' || (card.comments || []).length > 0;

        if (shouldUpdateActionList) {
          const card_text_line = `${'\r\n' + '\t'}${card.text} (${card.explanatory_text})`;
          section_action_list.push({ text: card_text_line, style: 'question' });
          section_action_list_text += card_text_line;

          if (card.comments) {
            const card_comment_line = `${'\r\n' + '\t\t'} - ${card.comments}`;
            section_action_list.push({ text: card_comment_line, style: 'question_note' });
            section_action_list_text += card_comment_line;
          }
        }

        const actionItems = Object.values(_cardActionItems[card.id] || {});

        actionItems.forEach(x => {

          if (shouldUpdateActionList) {
            const title_line = `${'\r\n' + '\t\t\t'} - ${x.title}`;
            section_action_list.push({ text: title_line, style: 'action_item' });
            section_action_list_text += title_line;

            x.comments.forEach((comment) => {
              const email = typeof comment.user === 'object' ? (comment.user && comment.user.email)  || '' : '';
              const datetime = this._datePipe.transform(comment.created_at, 'dd/MM/y, h:mm a');
              const comment_line = `${'\r\n' + '\t\t\t\t'}${email ? ' - ' + email : ''} - ${datetime} - ${comment.message}`;
              section_action_list.push({ text: comment_line, style: 'comment' });
              section_action_list_text += comment_line;
            })
          }

          if (([1, 3].includes(role) && x.status === 2 ) || ([2, 5].includes(role) && (x.status === 1 || x.status === 6))) {
            // Updates the action_items_needing_review count
            debugger
            submission_status.status += 1;
            submission_status.section[section.id].status += 1;
          }
        });
      });
      // >>>>>>>>>>>>>>>>>>>>>

      if (section_action_list.length > 0) {

        const section_text_line = `${'\r\n' + '\r\n'}${section.name}(${section.description})`;
        action_list.push({ text: section.name, style: 'section' });
        action_list_text += section_text_line;

        action_list = action_list.concat(section_action_list);
        action_list_text += section_action_list_text;
      }


    });

    action_list_text += '\r\n';

    const sections = submission.sections.map((sec) => ({
      ...sec,
      action_items_needing_review: submission_status.section[sec.id].status
    }));

    if (this.currentSubmission) {
      this.currentSubmission.action_list_text = action_list_text;
      this.currentSubmission.action_list = action_list;
      this.currentSubmission.sections = sections;

      this.currentSubmission.action_items_needing_review = submission_status.status;
    }

    return { action_list, action_list_text };
  }

  moveSubmisionDataAddSectionMetaData(oldSubmissionReference: SubmissionReduced, newSubmissionData: Submission): Submission {
    const returnSubmission = oldSubmissionReference as Submission;
    for (let j = 0; j < newSubmissionData.sections.length; j++) {

      newSubmissionData.sections[j] = Object.assign(newSubmissionData.sections[j], Data.updatePercentMetaData(
        newSubmissionData.sections[j].questions_answered,
        newSubmissionData.sections[j].questions_total,
        newSubmissionData.sections[j].points_allocated,
        newSubmissionData.sections[j].points_awarded,
        newSubmissionData.sections[j].points_available,
        newSubmissionData.sections[j].lci_count
      ));

      //   Data.calcSectionScores(this.currentAudit[this.auditSubmissions[j]]);
    }

    // done incase the user changes audit or submission or makes an edit to submission while waiting for data
    returnSubmission.sections = newSubmissionData.sections;
    returnSubmission.tables = newSubmissionData.tables;
    returnSubmission.action_list = newSubmissionData.action_list;
    returnSubmission.status = newSubmissionData.status;
    return returnSubmission;
  }


  switchSection(sectionID: number) {
    if (this.currentSection) {

    }
    // this.currentSectionID = sectionID;
    for (let i = 0; i < this.currentSubmission.sections.length; i++) {
      if (this.currentSubmission.sections[i].id === sectionID) {
        this.currentSectionID = sectionID;
        this.currentSection = this.currentSubmission.sections[i];
        break;
      }
    }
    this.loadBreadcrumb.next(true);

  }

  switchQuestion(questionID: number) {
    if (this.currentQuestion) {

    }
    // this.currentSectionID = sectionID;
    for (let i = 0; i < this.currentSection.card_set.length; i++) {
      if (this.currentSection.card_set[i].id === questionID) {
        this.currentQuestionID = questionID;
        this.currentQuestion = this.currentSection.card_set[i];


        break;
      }
    }
    this.loadBreadcrumb.next(true);
  }

  // updateQuestionAnswerByID(questionID: number, answer: string, comments: string, role: number) {
  //   // this is now pointless given the data referencing i have seen
  //   this.switchQuestion(questionID); // used to prevent data lose incase somebody is on another screen and switches back it will insert the
  //   // old question TODO maybe just check if its the same one


  //   let answerFlag = 0;
  //   let allocatedFlag = 0;
  //   let awardedFlag = 0;
  //   let lciFlag = 0;
  //   if (this.currentQuestion.answer === 'NONE') {
  //     answerFlag = 1;
  //   }
  //   if (this.currentQuestion.answer === 'N/A') {
  //     allocatedFlag = 1;
  //   }
  //   if (this.currentQuestion.answer === 'OK') {
  //     awardedFlag = -1;
  //   }
  //   if (this.currentQuestion.answer === 'FAIL' && this.currentQuestion.lci) {
  //     lciFlag = -1;
  //   }

  //   if (!answer) {
  //     answer = 'NONE';
  //   }


  //   this.currentQuestion.answer = answer;
  //   this.currentQuestion.comments = comments;

  //   if (this.currentQuestion.answer === 'NONE') {
  //     answerFlag -= 1;
  //   }
  //   if (this.currentQuestion.answer === 'N/A') {
  //     allocatedFlag -= 1;
  //   }
  //   if (this.currentQuestion.answer === 'OK') {
  //     awardedFlag += 1;
  //   }
  //   if (this.currentQuestion.answer === 'FAIL' && this.currentQuestion.lci) {
  //     lciFlag += 1; // remember increase lci count for every lci fail
  //   }

  //   // flag is 0 if question changed from an answer to an answer,
  //   //         1 if move from no answer to answer and
  //   //        -1 if moved from answer to no answer
  //   this.alterQuestion(answerFlag, allocatedFlag, awardedFlag, lciFlag);
  //   return this.restProvider.updateCardByID(this.currentQuestion)
  //     .then(res => this.updateActionItems({
  //       type: 'card_update',
  //       card: res.id,
  //       id: NaN,
  //       data: res,
  //       role
  //     }));
  // }
  updateQuestionAnswerByID(question: CardSet, answer: 'NONE' | 'N/A' | 'OK' | 'FAIL', role: number) {
    // updateQuestionAnswerByID(questionID: number, answer: 'NONE' | 'N/A' | 'OK' | 'FAIL', comments: string, managerNotes: string, role: number) {
    
    // this is now pointless given the data referencing i have seen
    this.switchQuestion(question.id); // used to prevent data lose incase somebody is on another screen and switches back it will insert the
    // old question TODO maybe just check if its the same one

    if (this.currentQuestion.answer === answer && 
        (question.comments !== '' && question.depot_manager_notes !== '')) { 
        return; 
    }

    let answerFlag = 0;
    let allocatedFlag = 0;
    let awardedFlag = 0;
    let lciFlag = 0;

    switch (this.currentQuestion.answer) {
      case 'NONE': answerFlag = 1; break;
      case 'N/A': allocatedFlag = 1; break;
      case 'OK': awardedFlag = -1; break;
      case 'FAIL': if (this.currentQuestion.lci) { lciFlag = -1; } break;
      default: break;
    }

    if (!answer) { answer = 'NONE'; }

    switch (answer) {
      case 'NONE': answerFlag -= 1; break;
      case 'N/A': allocatedFlag -= 1; break;
      case 'OK': awardedFlag += 1; break;
      // remember increase lci count for every lci fail
      case 'FAIL': if (this.currentQuestion.lci) { lciFlag += 1; } break;
      default: break;
    }

    this.currentQuestion.answer = answer;
    this.currentQuestion.comments = question.comments;
    this.currentQuestion.depot_manager_notes = question.depot_manager_notes;
    if (question.card_type === 'COMPLIANCE_RATIO') {
      this.currentQuestion.number_of_infringements = question.number_of_infringements;
      this.currentQuestion.number_of_working_days = question.number_of_working_days;
      this.currentQuestion.compliance_ratio = question.compliance_ratio;
    }
    

    // flag is 0 if question changed from an answer to an answer,
    //         1 if move from no answer to answer and
    //        -1 if moved from answer to no answer
    this.alterQuestion(answerFlag, allocatedFlag, awardedFlag, lciFlag);    
    return this.restProvider.updateCardByID(this.currentQuestion, role)
      .then(res => this.updateActionItems({
        type: 'card_update',
        card: res.id,
        id: NaN,
        data: res,
        role
      }));
  }

  addSignature(base64Image: string, sigName: string, auditorSig: boolean) {
    if (auditorSig) {
      const d = new Date();

      this.currentSubmission.depot_signoff_signature = base64Image;

      this.currentSubmission.depot_signoff_date = d.toISOString();
      this.currentSubmission.depot_signoff_name = sigName;
      this.currentSubmission.status = 3;
      return new Promise((resolve, reject) => {
        this.restProvider.submitSubmissionSignOffAuditor(this.currentSubmission).then((result) => {
          resolve(result);
        }, (error) => {
          // reset data as it failed to sync to the backend and a notification is sent to user
          this.currentSubmission.depot_signoff_signature = '';
          this.currentSubmission.depot_signoff_date = '';
          this.currentSubmission.depot_signoff_name = '';
          if (!this.currentSubmission.rtm_signoff_signature) {
            this.currentSubmission.status = 2;
          }
          reject(error);
        });
      });
      // return this.restProvider.submitSubmissionSignOffAuditor(this.currentSubmission);
    } else {
      const d = new Date();

      this.currentSubmission.rtm_signoff_signature = base64Image;
      this.currentSubmission.rtm_signoff_date = d.toISOString();
      this.currentSubmission.rtm_signoff_name = sigName;
      // this.currentSubmission.status = 3; // Removed this to allow only Complete status on Auditor signature
      // return this.restProvider.submitSubmissionSignOffDM(this.currentSubmission);

      return new Promise((resolve, reject) => {
        this.restProvider.submitSubmissionSignOffDM(this.currentSubmission).then((result) => {
          resolve(result);
        }, (error) => {
          // reset data as it failed to sync to the backend and a notification is sent to user
          this.currentSubmission.rtm_signoff_signature = '';
          this.currentSubmission.rtm_signoff_date = '';
          this.currentSubmission.rtm_signoff_name = '';
          if (!this.currentSubmission.depot_signoff_signature) {
            this.currentSubmission.status = 2;
          }
          reject(error);
        });
      });
    }
  }

  // NOTE this is going off current question but it may be that I need to pass in the question ID to keep everything acurate
  alterQuestion(answerFlag: number, allocatedFlag: number, awardedFlag: number, lciFlag: number) {
    const section: Section = Object.assign(this.currentSection, Data.updatePercentMetaData(
      this.currentSection.questions_answered + answerFlag,
      this.currentSection.questions_total,
      this.currentSection.points_allocated + (this.currentQuestion.points_available * allocatedFlag),
      this.currentSection.points_awarded + (this.currentQuestion.points_available * awardedFlag),
      this.currentSection.points_available,
      this.currentSection.lci_count + lciFlag
    ));

    this.currentSection = section;
    const sections = this.currentSubmission.sections.map((sec: Section) => (sec.id === section.id ? section : sec));

    this.currentSubmission = Object.assign(this.currentSubmission, { sections }, Data.updatePercentMetaData(
      this.currentSubmission.questions_answered + answerFlag,
      this.currentSubmission.questions_total,
      this.currentSubmission.points_allocated + (this.currentQuestion.points_available * allocatedFlag),
      this.currentSubmission.points_awarded + (this.currentQuestion.points_available * awardedFlag),
      this.currentSubmission.points_available,
      this.currentSubmission.lci_count + lciFlag
    ));
  }

  getTableById(tableID: number) {
    console.log('@@@@ this.currentSubmission.tables: ', this.currentSubmission.tables)
    for (let i = 0; i < this.currentSubmission.tables.length; i++) {
      if (tableID === this.currentSubmission.tables[i].id) {
        console.log('getTableById: tableID === this.currentSubmission.tables[i].id', this.currentSubmission.tables[i])
        return this.currentSubmission.tables[i];
      }
    }
    console.log('getTableById: tableID return null')
    return null
  }

  // currently no longer needed
  /*replaceTableById(table: any, tableID: number) {
   for (let i = 0; i < this.currentSubmission.tables.length; i++) {
   if (tableID === this.currentSubmission.tables[i].id) {
   this.currentSubmission.tables[i] = table;
   break;
   }
   }
   }*/

  getBreadcrumbName(input) {
    let returnString = '';
    if (this.loadingAuditList.value !== true) {
      switch (input) {
        // case 'Login':
        //   return 'DPD FCR App';
        // case 'auditList':
        //   returnString = 'FCR Dashboard';
        //   break;
        case 'submissionList':
          returnString = this.currentAudit.name;
          break;
        case 'sectionList':
          returnString = this.currentSubmission.name;
          break;
        case 'questionList':
          returnString = this.currentSection.name;
          break;
        case 'Attachments':
          returnString = this.currentQuestion.text + ' attachments';
          break;
        case 'New attachments':
          returnString = 'Add new attachment';
          break;

      }
    }


    return returnString;
  }

  pushTableUpdate(tableID: number) {
    let table;
    for (let i = 0; i < this.currentSubmission.tables.length; i++) {
      if (tableID === this.currentSubmission.tables[i].id) {
        table = this.currentSubmission.tables[i];
      }
    }


    this.restProvider.updateTableByID(table).then((res) => console.log("res>>>>>>>>", res));
  }

  refreshAttachmentsForCard(card: any) {
    this.loadingCardAttachments.next(true);
    let fileGroupsReceived = 0;
    this.restProvider.getCardById(card.id)
      .then((result) => {
        card = result; // todo contains file group data so I don't need to call the file groups individually
        // this.currentQuestion = card; // don't want to do this as it will overwrite the current question's data ad not write it into the
        // audit list data
        for (let i = 0; i < card.file_groups.length; i++) {
          this.restProvider.getAttachmentsById(card.file_groups[i].id)
            .then((result) => {
              this.updateAttachment(card, result);
              fileGroupsReceived++;
              if (fileGroupsReceived === card.file_groups.length) {
                this.loadingCardAttachments.next(false);
              }
            }, (err) => {
              console.log(err);
            });
        }

      }, (err) => {
        console.log(err);
      });
  }


  updateAttachment(card: CardSet, updatedFileGroup: any) {
    if (this.currentQuestionID === card.id) {
      let found = false;
      for (let i = 0; i < this.currentQuestion.file_groups.length; i++) {
        if (this.currentQuestion.file_groups[i].id === updatedFileGroup.id) {
          this.currentQuestion.file_groups[i] = updatedFileGroup;
          found = true;
          break;
        }
      }
      if (!found) {
        this.currentQuestion.file_groups.push(updatedFileGroup);
      }
    } else {
      console.log('Question has changed before refresh'); // TODO should solve this problem
    }
  }

  updateFileGroup(fileGroup: FileGroup, suggestedPosition: number = -1) {
    let updated = false;
    if (suggestedPosition !== -1 && fileGroup.id === this.currentQuestion.file_groups[suggestedPosition].id) {
      this.currentQuestion.file_groups[suggestedPosition] = fileGroup;
      updated = true;
    } else {
      for (let i = 0; i < this.currentQuestion.file_groups.length; i++) {
        if (fileGroup.id === this.currentQuestion.file_groups[i].id) {
          this.currentQuestion.file_groups[i] = fileGroup;
          updated = true;
          break;
        }
      }
    }
    // todo consider expanding the search up the tree
    return updated;
  }

  addFileGroup(fileGroup: FileGroup) {
    if (this.currentQuestion.id === fileGroup.card) { // double check its linked to this question
      this.currentQuestion.file_groups.push(fileGroup);
      return true;
    }
    return false;
  }
}
